<template>
  <div id="app">
    <!-- <router-link :to="{name:'Home'}">首页</router-link>
    <router-link :to="{name:'Login'}">登录</router-link> -->
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>

</style>