const routes = [
    // { path: "/coporations", name: 'coporations', label: '企业登记', component: () => import('./views/coporations.vue'), },
    // { path: "/contracts", name: 'contracts', label: '合同管理', components: {test1:() => import('./views/contracts.vue')}, },
    // { path: "/projects", name: 'projects', label: '审核进度', component: () => import('./views/projects.vue'), },
    // { path: "/fee", name: 'fee', label: '入账管理', component: () => import('./views/fee.vue'), },
    // { path: "/plans", name: 'plans', label: '计划安排', component: () => import('./views/plans.vue'), },
    { path: "/", name: 'Login', label: '登录', component: () => import('./components/Login.vue'), },
    { 
        path: "/home", 
        name: 'Home', 
        label: '首页', 
        component: () => import('./components/Home.vue'),
        children: [
            { 
                path: "/coporations", 
                name: 'coporations', 
                label: '企业登记', component: () => import('./views/coporations.vue'),
                meta: {
                    // keepAlive: true,
                    authentication: true,
                    title: 's',
                }
            },
            { 
                path: "/contracts", 
                name: 'contracts', 
                label: '合同管理', component: () => import('./views/contracts.vue'),
                meta: {
                    // keepAlive: true,
                    authentication: true,
                    title: 'S',
                }  
            },
            { 
                path: "/projects", 
                name: 'projects', 
                label: '审核进度', component: () => import('./views/projects.vue'), 
                meta: {
                    // keepAlive: true,
                    authentication: true,
                    title: 'SS',
                }
            },
            { 
                path: "/fee", 
                name: 'fee', 
                label: '入账管理', component: () => import('./views/fee.vue'), 
                meta: {
                    // keepAlive: true,
                    authentication: true,
                    title: 'SSS',
                }
            },
            { 
                path: "/gongzi", 
                name: 'gongzi', 
                label: '工资管理', component: () => import('./views/gongzi.vue'), 
                meta: {
                    // keepAlive: true,
                    authentication: true,
                    title: 'SSSS',
                }
            },
            // { 
            //     path: "/receive", 
            //     name: 'receive', 
            //     label: '收件管理', component: () => import('./views/receive.vue'), 
            //     meta: {
            //         keepAlive: true,
            //         authentication: true,
            //         title: 'SSSS',
            //     }
            // },
            // { 
            //     path: "/receive", 
            //     name: 'receive', 
            //     label: '收件管理', component: () => import('./views/receive.vue'), 
            //     meta: {
            //         keepAlive: true,
            //         authentication: true,
            //         title: 'SSSS',
            //     }
            // },
        ]
    },
]
// const routes2 = [
//     { path: "/home", name: 'Home', label: '首页', component: () => import('./components/Home.vue'), },
//     { path: "/", name: 'Login', label: '登录', component: () => import('./components/Login.vue'), },
// ]
export default routes