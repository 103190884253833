import {createRouter,createWebHashHistory} from "vue-router"
import routes from '../routes'
// import Home from "../components/Home.vue"   //引入路由组件
// import Login from "../components/Login.vue" 
 
const router = createRouter({   //createRouter   vue2中是 new Vue
	history : createWebHashHistory(),  //createWebHashHistory  ，v3文档 API里有 ，将模式修改 ，Vue2中是mode :history
	routes: routes
    // [{  //配置路由规则
	// 		name: 'login',
	// 		path: '/', //默认路径
	// 		component: Login
	// 	},
 
	// 	{
	// 		name: 'home',
	// 		path: '/Home',
	// 		component: Home  //动态路由的写法
	// 	}
	// ]
    
})

export default router
